import styled from "styled-components";

export const ModalStyle = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  z-index:9;
  .modal-wrapper.carousel-modal {
    width: 90%;
    max-width: 700px;
    height: 570px;
  }
  .modal-wrapper {
    width: 90%;
    max-width: 800px;
    max-height: 80vh;
    padding: 40px 20px 20px;
    position: relative;
    border-radius: 5px;
    background-color: white;
    display:flex;
    flex-direction:column;
    justify-content:space-between;

    h4 {
      font-family: 'Georgia';
      padding-left: 1rem;
    }

    img {
      width: 100%;
      height: auto;
    }
    .btns{
      display:flex;
      justify-content: flex-end;
      align-items: center;
      padding-left: 1rem;
    }
    @media (max-width: 768px) {
      h4, .btns {
        padding: 0px;
      }
    }
    #actionBtn {
      padding:1rem 4rem;
      background-color: black;
      border-radius:.5rem;
      color:white;
      width: fit-content;
      align-self: flex-end;
    }
    .getStarted {
      text-align: right;
      margin: 0.5rem;
    }
    .close {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 1.5rem;
    }
    .modal-button {
      background-color: #333;
      color: white;
      width: 100%;
      border-radius: 5px;
      padding: 10px 20px;
    }
    .step_wrapper {
      margin-top: 20px;
    }
    .step_caption {
      margin-top: 20px;

      h5, p {
        display: inline-block;
      }
    }
  }

  .pro-lock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.25rem;
    height: 3.25rem;
    margin-right: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid #333;
    
    img {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
`;
