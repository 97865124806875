import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { ModalStyle } from './modal.style';
import Lock from '../../../images/lock.svg';

const Modal = ({ show, name, imgUrl, id, free }) => {

  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (show) setDisplay(true);
  }, [show])

  useEffect(() => {
    setDisplay(false);
  }, [])

  const handleClick = () => {
    setDisplay(false)
  }


  let modalDisplay = display ? 'flex' : 'none';

  return (
    <ModalStyle style={{ display: modalDisplay }}>
      <div className="modal-wrapper">
        <p onClick={handleClick} className="close" src="/close.svg" alt="close" >&#10006;</p>
        <h4>{name}</h4>
        <div className="w-full h-full overflow-y-auto my-8">

          <img width="600px" src={imgUrl} alt="template preview"></img>
        </div>
        <a href="https://windframe.dev/pricing" className="getStarted">Get Premium</a>
        <div className="btns">
          {!free ?
            <Link to="/windframe/pricing" className="pro-lock">
              <img src={Lock} alt="lock" />
            </Link>
            : ''}
          {process.env.NODE_ENV === 'production' && <a id="actionBtn" href={`https://windframe.devwares.com/editor?id=${id}`} >Start Editing</a>}
          {process.env.NODE_ENV === 'development' && <a id="actionBtn" href={`http://localhost:3000/editor?id=${id}`} >Start Editing</a>}
        </div>
      </div>
    </ModalStyle>
  );
}

export default Modal;
