import React, { useState } from 'react';

import './Block.scss';
import Modal from '../Modal/Modal';
import Lock from '../../../images/lock.svg';

export const Block = props => {
  const [display, setDisplay] = useState(false);
  const imgUrl = props.block.imgUrl
  const imageUrl = typeof imgUrl === "string" && imgUrl.toLowerCase().startsWith("http") ? imgUrl : `/selectorImages/${imgUrl}`
  return (
    <>
      <div onClick={() => setDisplay(!display)} className="wf_block">
        <img width="100%" src={`${imageUrl}`} alt=""/>
        <div className="details">
          <p>{props.block.name}</p>
          {!props.block.free ? (
            <div className="pro-lock">
              <img src={Lock} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      <Modal
        show={display}
        free={props.block.free}
        id={props.id}
        name={props.block.name}
        imgUrl={imageUrl}
      />
    </>
  );
};
