import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from '../../../components/AppContext';
import NavBar from '../../../components/NavBar/WindframeNav';
import { Footer } from '../../../components/Footer/Footer';
import { CtaBlocks } from '../../../components/Windframe/Windframe/CtaBlocks';
import { Block } from '../../../components/Windframe/Windframe/Block/Block';
import loading from '../../../components/images/loading.gif';
import { Link } from 'gatsby';
import '../../../styles/scss/style.scss'
import './blocks.scss';

const Blocks = () => {

  return (
    <AppContext>
      <Helmet>
        <title>
          Windframe Full Page Templates - Tailwind CSS full page templates to rapidly prototype and build any web
          page
        </title>
        <meta
          name="title"
          content="Windframe Full Pages - Tailwind CSS full page templates to rapidly prototype and build any web page"
        />
        <meta
          name="description"
          content="Windframe Full Pages is a collection of full page templates built with tailwind css, to rapidly build a webpage or prototype any idea"
        />
        <meta
          property="og:title"
          content="Windframe Full Pages - Tailwind CSS full page templates to rapidly prototype and build any web page"
        />
        <meta
          property="og:description"
          content="Windframe Full Pages is a collection of full page templates built with tailwind css, to rapidly build a webpage or prototype any idea"
        />
        <meta
          property="twitter:title"
          content="Windframe Full Pages - Tailwind CSS full page templates to rapidly prototype and build any web page"
        />
        <meta
          property="twitter:description"
          content="Windframe Full Pages is a collection of full page templates built with tailwind css, to rapidly build a webpage or prototype any idea"
        />
        <link rel="canonical" href="https://windframe.dev/full-pages/" />
        <meta property="og:url" content="https://windframe.dev/full-pages/" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <UserContext.Consumer>
        {({ user, blocks }) => (
          <div>
            {/* <div className="w-full h-10 px-6 flex items-center justify-center bg-orange-500 text-white">
              <p className="text-xl">
                We are live on product hunt today 🚀 and would appreciate your support!
              </p> */}
            {/* <a href="https://www.producthunt.com/posts/windframe-2-0-2" className="">
                <img
                  src="https://assets.stickpng.com/images/580b57fcd9996e24bc43c534.png"
                  alt="ad-banner"
                  className="rounded-full w-10 h-10"
                />
              </a> */}
            {/* <a
                href="https://www.producthunt.com/posts/windframe-2-0-2"
                className="px-3 text-white underline text-xl font-bold !underline"
                style={{ textDecoration: 'underline' }}
              >
                Support on Product Hunt
              </a>
              </div> */}
            <div className="container">
              <NavBar user={user} />
            </div>
            <div className="py-5" >
              <div className="container">
                <div className="hero">
                  <div className="hero__wrap">
                    <div className="hero__header">
                      <h2 className="hero__lead " style={{ maxWidth: "600px", margin: "auto" }}>
                        Full page templates to rapidly prototype and build any web page
                      </h2>
                      <p className="hero__text" style={{ maxWidth: "700px" }}>
                        Windframe full page is a collection of full page templates built with tailwind css, to rapidly build a webpage or prototype any idea.
                        These tailwind css full pages plugs in directly to <a href="https://windframe.dev/"> Windframe </a>(a design, prototyping and code tool) to enable you easily edit, preview, save or export your designs
                      </p>

                      <div className="hero__buttons">
                        <Link href="https://windframe.dev/" className="">
                          <span className="btn__text">Learn more about windframe</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="blocks-container container">
              {Object.keys(blocks).length > 0 ? (
                <>
                   <div className="block-section">
                    <h3>Full Pages</h3>
                    <div className="blocklist">
                      {blocks?.fullPages
                        ?.filter(block => block.free)
                        .map(block => (
                          <Block id={block.id} block={block} key={block?.id} />
                        ))}
                      {blocks?.fullPages
                        ?.filter(block => !block.free)
                        .map(block => (
                          <Block id={block.id} block={block} key={block?.id} />
                        ))}
                      {/* {blocks?.header?.map((block)=><Block id={block.id} block={block} key={block?.id} />)} */}
                    </div>
                  </div>
                </>
              ) : (
                <div className="loading">
                  <img src={loading} alt="loading gif" style={{ margin: 'auto' }} />
                </div>
              )}
            </div>
            <CtaBlocks />
            <div className="container">
              <Footer />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default Blocks;
