import React from 'react';
import { Link } from 'gatsby';


export const CtaBlocks = () => {

  return (    
      <div className="text-light py-5" style={{ background: 'rgba(26,32,44,1)' }}>
        <div className="container">
          <div className="hero mt-5">
            <p className="text-center mb-1">
              Windframe is a drag and drop builder for rapidly building websites and prototypes
            </p>
            <h1 className="text-center mb-5 mx-auto" style={{ maxWidth: '720px' }}>
              Start building your website&nbsp;
            </h1>

            <div className="hero__buttons text-center mt-5 flex justify-center">
              <Link href="https://windframe.dev/" className="btn1 hero__btn btn__inverse">
                <span className="btn__text">Learn more about Windframe</span>
              </Link>
            </div>

            {/* <p className="text-center mt-2">View full <Link to="/windframe">documentation here</Link></p> */}
          </div>
        </div>
      </div>
  );
};

